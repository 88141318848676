import React from 'react';
import { FileText } from 'lucide-react';

interface Document {
    id: string;
    name: string;
    path: string;
}

const documents: Document[] = [
    {
        id: '1',
        name: 'Protectia datelor cu caracter personal',
        path: '/docs/Protectia-si-prelucrarea-datelor-cu-caracter-personal.pdf'
    },
    {
        id: '2',
        name: 'Termeni si conditii',
        path: '/docs/Termeni-si-conditii.pdf'
    },
];

export const Documents: React.FC = () => {
    const handleDownload = (doc: Document) => {
        const fullPath = process.env.PUBLIC_URL + doc.path;
        window.open(fullPath, '_blank');
    };

    return (
        <div className="documents-container flex flex-col items-center gap-3 mb-6">
            <div className="flex flex-col md:flex-row gap-3 md:w-auto min-w-[100px] w-full">
                {documents.map((doc) => (
                    <button
                        key={doc.id}
                        onClick={() => handleDownload(doc)}
                        className="flex items-center gap-2 px-4 py-2 text-text-color text-base rounded hover:bg-opacity-90 transition-colors"
                    >
                        <FileText className="w-5 h-5" />
                        {doc.name}
                    </button>
                ))}
            </div>
        </div>
    );
};