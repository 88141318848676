import React from 'react';
import {Shield, Star, Phone, FileCheck, Clock, HandshakeIcon} from 'lucide-react';
import '../styles/main.css';

const productsServices = [
    {
        icon: <FileCheck className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Negocierea și încheierea polițelor de asigurare pentru toate tipurile de asigurări și cu cele mai puternice societăți de asigurări, ca de exemplu:'
    },
    {
        icon: <Shield className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Asigurări auto: RCA, CASCO, CMR.'
    },
    {
        icon: <Shield className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Asigurarea utilajelor neînmatriculate (agricole, construcții, drumuri etc).'
    },
    {
        icon: <HandshakeIcon className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Asigurări agricole, inclusiv secetă, îngheț timpuriu, îngheț târziu.'
    },
    {
        icon: <HandshakeIcon className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Asigurarea clădirilor și bunurilor (IF, PFA, Asociații, PJ etc), inclusiv pierderi de profit în caz de eveniment asigurat.'
    },
    {
        icon: <Shield className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Asigurarea panourilor solare.'
    },
    {
        icon: <Clock className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Asigurări echipamente electronice, servere, drone, protecție împotriva atacurilor cibernetice.'
    },
    {
        icon: <Shield className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Asigurarea locuințelor (PAD-Obligatorie și Facultativă).'
    },
    {
        icon: <FileCheck className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Asigurări medicale pentru călătorii în străinătate, accidente, sănătate.'
    },
    {
        icon: <FileCheck className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Asigurări de Bunuri și Persoane pe timpul transportului.'
    },
    {
        icon: <Clock className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Asigurări de răspundere civilă (producători, prestatori de servicii, chiriași) și profesională (avocați, notari, juriști, experți, contabili, medici, personal medical, lichidatori, manageri, membrii CA etc).'
    },
    {
        icon: <HandshakeIcon className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Asigurări de garanții (ARR, participare la licitații, garanții buna execuție, returnare avans, garanții comerciale).'
    },
    {
        icon: <Shield className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Asigurarea lucrărilor de construcții-montaj (CAR).'
    },
    {
        icon: <FileCheck className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Asistență gratuită pe toată durata valabilității polițelor de asigurări (acte adiționale, înștiințări/incasare rate, rezilieri).'
    },
    {
        icon: <FileCheck className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Asistență în legătură cu obținerea de despăgubiri de la asiguratori și întocmirea dosarelor de daună, inclusiv Carte Verde.'
    },
    {
        icon: <Star className="service-icon w-6 h-6 md:w-10 md:h-10"/>,
        text: 'Inspecții de risc în vederea obținerii ofertelor de asigurare de la asiguratori.'
    }
];


export const Services: React.FC = () => {
    return (
        <div className="card-content text-xl font-font-s text-bg-gray p-10 w-full md:w-11/12 gap-5 flex flex-col">
            <div>
                <p>
                    {'STAR INSURANCE SPECIALIST SRL are sediul social in Municipul Botosani, str. ' +
                        'Armeana nr. 50, Cod postal 710359, Judetul Botosani si isi desfasoara activitatea de ' +
                        'intermediere in asigurari la sediile situate in Municipiul Botosani, Judetul Botosani:'}
                </p>
                <p>
                    {'  1. Piata Revolutiei nr. 13, parter, (in spatele Teatrului Mihai Eminescu, langa Prefectura, ' +
                        'CEC Bank si Galeriile de arta Stefan Luchian), tel: 0331.402.564, Mobil/WhathApp: 0740.207.134;'}
                </p>
                <p>
                    {'2. Str. Armeana nr. 50, (Piata Mare, langa Biserica Armeneasca veche), tel: 0331.402.564, ' +
                        'Mobil/WhathApp: 0740.039.340.'}
                </p>
            </div>

            <p className="font-font-s text-2xl italic mt-5">
                {'Produsele/Serviciile noastre'}
            </p>
            <div className="services flex flex-col font-font-s gap-4 items-center md:items-start justify-center">
                {productsServices.map((service, index) => (
                    <div key={index} className="service-item flex flex-row gap-1 items-center w-full">
                        <p className={'w-1/12'}>{service.icon}</p>
                        <p className={'w-11/12 font-font-s'}>{service.text}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};