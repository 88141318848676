import React from 'react';

const navigationLinks = [
    {name: 'Verifica valabilitate RCA', url: 'https://www.aida.info.ro/polite-rca'},
    {name: 'Verifica valabilitate ITP', url: 'https://prog.rarom.ro/rarpol/'},
    {name: 'Verifica valabilitate Rovigneta', url: 'https://www.cnadnr.ro/ro/verificare-rovinieta'},
];

// Creăm un eveniment custom pentru deschiderea popup-ului
export const openCalculator = () => {
    const event = new CustomEvent('openCalculator');
    window.dispatchEvent(event);
};

export const Header: React.FC = () => {
    return (
        <header className="header flex items-center justify-center p-5">
            <div className="container header-content flex flex-col items-center justify-between max-w-[1240px] gap-10">
                <div className="logo-container w-full gap-5 flex flex-col md:flex-row justify-between ">
                    <div className={'flex flex-col items-start justify-center w-full md:w-4/6'}>
                        <h1 className={'font-font-s text-4xl font-bold text-main-color text-center md:text-left'}>
                            STAR INSURANCE SPECIALIST SRL
                        </h1>
                        <a className={'font-font-s italic font-bold text-main-color text-center md:text-left text-xs lowercase'}
                           href={'https://asfromania.ro/app.php/ro/a/1704/intermediari-secundari'}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            {' INREGISTRAT IN REGISTRUL ASISTENTILOR IN BROKERAJ PERSOANE JURIDICA, COD: '}
                            <span className={'uppercase'}>{'RAJ 502061'}</span>
                        </a>
                    </div>

                    <div className={'w-full md:w-2/6'}>
                        <p className={'font-font-s text-base font-bold text-main-color text-center md:text-right'}>
                        {'PARTENER FRANCIZAT AL '}
                        </p>
                        <a className={'text-2xl font-font-s font-bold text-main-color text-center md:text-right w-full flex'}
                           href={'https://www.renomia.ro/'}
                        >
                            {'RENOMIA INSURANCE REINSURANCE BROKER SRL'}
                        </a>
                    </div>
                </div>
                <div className="flex gap-4 flex-col md:flex-row w-full items-center justify-center">
                    <button
                        onClick={openCalculator}
                        className={'px-4 py-2 font-font-s bg-main-color text-white rounded hover:shadow-2xl transition-colors min-w-[100px] text-center'}
                    >
                        {'Calculeaza Asigurarea'}
                    </button>
                    {navigationLinks.map((link, index) => (
                        <a
                            key={index}
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={'px-4 py-2 font-font-s bg-main-color text-white rounded hover:shadow-2xl transition-colors min-w-[100px] text-center'}
                        >
                            {link.name}
                        </a>
                    ))}
                </div>
            </div>
        </header>
    );
};
