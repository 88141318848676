import React from 'react';
import {Header} from 'components/Header';
import {Services} from 'components/Services';
import {Footer} from 'components/Footer';
import {GoogleAnalytics} from 'components/GoogleAnalytics';
// import {Iframe} from "components/Iframe";
import {Partners} from "components/Partners";
import {Banner} from "components/Banner";

const MainPage: React.FC = () => {

    return (
        <div className="app">
            <GoogleAnalytics gaCode="G-XXXXXXXXXX"/>
            <Header/>
            <div className={'w-full flex flex-col items-center justify-center'}>
                <main className={'main-content flex flex-col items-center max-w-[1440px] bg-main-color shadow-lg p-5'}>
                    <div className="card flex flex-col gap-5 items-center">
                        <Banner/>
                        <Services/>
                        <Partners/>
                        {/*<Iframe/>*/}
                    </div>
                </main>
            </div>
            <Footer/>
        </div>
    );
};

export default MainPage;