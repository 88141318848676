import React, {useState} from 'react';
import {Documents} from './Documents';

export const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear();
    const [isAboutOpen, setIsAboutOpen] = useState(false);
    const [isContactOpen, setIsContactOpen] = useState(false);

    const toggleAboutPopup = () => {
        setIsAboutOpen(!isAboutOpen);
    };

    const toggleContactPopup = () => {
        setIsContactOpen(!isContactOpen);
    };

    return (
        <footer className="footer bg-bg-gray min-h-[200px] flex flex-col items-center justify-end">
            <Documents/>
            {/* Button to open the About Us popup */}
            <div className="contact-info flex flex-row items-center gap-3 mb-4">
                <button
                    onClick={toggleAboutPopup}
                    className="text-text-color hover:text-blue-600 transition-colors p-2 text-base"
                >
                    {'Despre noi'}
                </button>
                <button
                    onClick={toggleContactPopup}
                    className="text-text-color hover:text-blue-600 transition-colors p-2 text-base"
                >
                    {'Contact'}
                </button>
                <button
                    className="text-text-color hover:text-blue-600 transition-colors p-2 text-base"
                >
                    {'Petitii'}
                </button>
            </div>
            {/* Footer copy */}
            <div className="footer-copy flex items-center justify-center mb-5">
                <p className="text-text-color text-base">&copy; {currentYear} Star Insurance</p>
            </div>

            {/* Popup Modal for About Us */}
            {isAboutOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-5 rounded-md shadow-md w-11/12 max-w-lg">
                        <h3 className="text-xl font-bold mb-4">{'Despre noi'}</h3>
                        <p className="text-base text-text-color">
                            {'www.starinsurance.ro este operat de către societatea comercială STAR INSURANCE ' +
                                'SPECIALIST SRL, care este înregistrată în Registrul Comerțului sub nr. J7/261/2019, CUI ' +
                                '40722931 și înregistrată la ASF cu codul unic RAJ-502061 ca asistent în brokeraj al ' +
                                'RENOMIA INSURANCE REINSURANCE BROKER SRL.'}
                        </p>

                        {/* Close button */}
                        <button
                            onClick={toggleAboutPopup}
                            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                        >
                            Închide
                        </button>
                    </div>
                </div>
            )}

            {/* Popup Modal for Contact */}
            {isContactOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-5 rounded-md shadow-md w-11/12 max-w-2xl">
                        <h3 className="text-xl font-bold mb-4">{'Contact'}</h3>
                        <div className="text-base text-text-color">
                            <p>
                                {'Mail: doina.tomuleanu@renomia.ro; gheorghe.tomuleanu@renomia.ro'}
                            </p>
                            <p>
                                {'Program de lucru in zilele lucratoare legal: Luni – Vineri: 9,00 – 17,00'}
                            </p>
                            <p>
                                {'Urgente in afara programului de lucru: Tel/WhathApp: 0740.039.340 ; 0740.207.134'}
                            </p>

                        </div>

                        {/* Close button */}
                        <button
                            onClick={toggleContactPopup}
                            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                        >
                            Închide
                        </button>
                    </div>
                </div>
            )}

        </footer>
    );
};