import React from 'react';

export const Banner: React.FC = () => {
    const getImagePath = () => {
        const basePath = process.env.PUBLIC_URL || '';
        const imagePath = '/images/sediu-insurance.jpg';

        return `${basePath}${imagePath}`;
    };

    return (
        <div className="banner w-full">
            <a
                href="https://www.google.com/search?client=firefox-b-d&sa=X&sca_esv=1c5d2991ca1b4824&hl=ro-RO&tbm=lcl&sxsrf=ADLYWIICyUdW_nliCd8dw7t_l_jXhI4-AQ:1731569835160&q=RENOMIA%20SRBA%20-%20Partener%20Star%20Insurance%20Specialist%20Recenzii&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNDE1MzYyNTc2MTG0tDQ0NTGxMNjAyPiK0SrI1c_f19NRITjIyVFBVyEgsagkNS-1SCG4JLFIwTOvuLQoMS85VSG4IDU5MzEns7hEISg1OTWvKjNzESsFmgGU1rm7lQAAAA&rldimm=14563257344199154480&ved=0CAcQ5foLahcKEwiIzYapqNuJAxUAAAAAHQAAAAAQCQ&biw=1920&bih=899&dpr=1#lkt=LocalPoiReviews&arid=ChZDSUhNMG9nS0VJQ0FnSUNKc2FtY1BREAE"
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full h-[200px] xl:h-[400px] cursor-pointer hover:opacity-90 transition-opacity bg-main-color "
                title={'Vezi sediul nostru pe Google Maps'}
                style={{
                    background: `url("${getImagePath()}")`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: '#054189'
                }}
                onError={(e) => {
                    console.error('Error loading background image:', getImagePath());
                }}
            >
                {/* Fallback content în caz că imaginea nu se încarcă */}
                <div
                    className="w-full h-full flex items-center justify-center text-gray-500"
                    style={{ display: 'none' }}
                >
                    Sediul Star Insurance
                </div>
            </a>
        </div>
    );
};