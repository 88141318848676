import React, { useEffect, useCallback, useState } from 'react';

export const Partners: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleMessage = useCallback((e: MessageEvent) => {
        if (typeof e.data === 'object' && e.data?.source?.includes('react-devtools')) {
            return;
        }

        const iframe = document.getElementById('remoteIframe') as HTMLIFrameElement;
        if (Array.isArray(e.data)) {
            const [eventName, data] = e.data;
            if (eventName === 'setHeight' && iframe) {
                iframe.style.height = `${data}px`;
            }
        }
    }, []);

    useEffect(() => {
        // Handler pentru deschiderea popup-ului
        const handleOpenCalculator = () => {
            setIsVisible(true);
        };

        window.addEventListener('message', handleMessage);
        window.addEventListener('openCalculator', handleOpenCalculator);

        return () => {
            window.removeEventListener('message', handleMessage);
            window.removeEventListener('openCalculator', handleOpenCalculator);
        };
    }, [handleMessage]);

    // Handler pentru închiderea popup-ului cu ESC
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setIsVisible(false);
            }
        };

        if (isVisible) {
            window.addEventListener('keydown', handleEsc);
        }

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [isVisible]);

    return (
        <>
            {isVisible && (
                <div
                    className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-4"
                    onClick={(e) => {
                        // Închide popup-ul când se dă click pe overlay
                        if (e.target === e.currentTarget) {
                            setIsVisible(false);
                        }
                    }}
                >
                    <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-auto">
                        <div className="flex justify-between items-center p-4 border-b">
                            <p className="text-xl font-font-s text-bg-gray">Calculator asigurare</p>
                            <button
                                onClick={() => setIsVisible(false)}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                ✕
                            </button>
                        </div>
                        <div className="p-4">
                            <iframe
                                id="remoteIframe"
                                className="iframe w-full"
                                scrolling="yes"
                                style={{
                                    overflow: 'hidden',
                                    border: 'none',
                                    minHeight: '500px',
                                    maxHeight: 'calc(90vh - 120px)'
                                }}
                                src="https://test.srba.ro/siteIframe/index.php?partnersId=17&env=0"
                                title="Insurance Calculator"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};